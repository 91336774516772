/**
 * @file Render module for SingleCustomer Component
 * @author Mike Chacon
 */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import queryString from "query-string";
import DonorInfo from "./donor-info";
import Loader from "components/loader";
import DonorLocations from "./donor-locations";
import DonorPickups from "./donor-pickups";
import DonorTimeline from "./donor-timeline";
import GeneratePin from "pages/donors/donor-single/generate-pin";
import { Container, Col, Row } from "react-bootstrap";
import Box from "components/box";
import Modal from "components/modal";
import Tabs from "components/tabs";
import CreateUpdatePickup from "forms/create-update-pickup";
import Notes from "forms/notes";
import { usersNotesAPI } from "api/users";
import { UserProvider } from "contexts/user";
import NotificationSettings from "forms/notification-settings";
import { useWindowDimensions } from "utils/helpers";

const SingleCustomerComponent = ({
  requestCustomer,
  match,
  customer: customerData,
  createPickup,
  requestCustomerActions,
  requestPickups,
}) => {
  const [loading, setLoading] = useState(true);
  const [displayForm, setDisplayForm] = useState(false);
  const [formLocationId, setFormLocationId] = useState(null);
  const [activeTab, setActiveTab] = useState("notes");
  const { width } = useWindowDimensions();
  const columnFormat = (() => {
    if (width < 850) {
      return 12;
    }
    if (width < 1050) {
      return 6;
    }
    return 4;
  })();

  useEffect(() => {
    requestData();
  }, []);

  const openForm = (id) => {
    setFormLocationId(id);
    setDisplayForm(true);
  };
  const closeForm = () => {
    setDisplayForm(false);
    setFormLocationId(null);
  };

  const requestData = async () => {
    // Make all requests for the customer page ( TODO: Refactor in to a single endpoint for speed )
    // const {
    //   requestCustomer,
    //   // requestCustomerActions,
    //   // requestPickups,
    //   // requestLeadSources,
    //   // clearPickupBlocks
    // } = this.props;

    // const customer = await requestCustomer(this.props.match.params.customerId)
    // const customerActions = await requestCustomerActions(this.props.match.params.customerId)
    // const pickups = await requestPickups({userId: this.props.match.params.customerId})
    // const leadSources = await requestLeadSources()
    Promise.all([
      requestCustomer(match.params.customerId),
      // requestCustomerActions(this.props.match.params.customerId),
      // requestPickups({userId: this.props.match.params.customerId}),
      // requestLeadSources(),
    ]).then(() => {
      // TODO: Shouldn't be necessary
      // clearPickupBlocks();

      // Done Loading
      setLoading(false);
    });
  };

  const { customer } = customerData;

  const locations = customer.locations || [];

  //
  // // Want access to locations, but not duplicate data
  // // Index array by location Id here for easy access later
  // const locationsIndex = {};
  // locations.forEach((location, index) => {
  //   locationsIndex[location.id] = index;
  // });
  //
  // this.locationsIndex = locationsIndex;

  let isLoading = customerData.isFetching || !customerData.loaded || loading;

  // Is Loading
  if (isLoading) {
    return (
      <Loader color={process.env.REACT_APP_MP_PRIMARY_COLOR} width="10rem" />
    );
  }

  return (
    <UserProvider user={customer}>
      {/*This form is used to create a new pickup using the create pickup button in the location form*/}
      <Modal show={displayForm} onHide={closeForm} title="New Pickup">
        <CreateUpdatePickup
          userId={match.params.customerId}
          customerId={match.params.customerId}
          onCancel={closeForm}
          onSubmit={async (data) => {
            await createPickup(data);
            await requestCustomerActions(match.params.customerId);
            await requestPickups({ userId: match.params.customerId });
          }}
          onSubmitSuccess={closeForm}
          initialValues={{ locationId: formLocationId }}
        />
      </Modal>

      <Container fluid>
        <Row>
          <Col sm={columnFormat}>
            <h3>Donor Info</h3>
            <DonorInfo customer={customer} />

            <Box>
              <NotificationSettings />
            </Box>

            {customer.userTypeId !== 5 ? (
              <Box>
                <h3 className="list-group-item-heading">Account Access</h3>
                <GeneratePin customer={customer} />
              </Box>
            ) : (
              false
            )}
          </Col>

          <Col sm={columnFormat}>
            <DonorLocations
              donorId={match.params.customerId}
              formProps={{ openCreatePickupForm: openForm }}
            />
          </Col>

          <Col sm={columnFormat}>
            <DonorPickups
              donorId={match.params.customerId}
              customer={customer}
            />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Tabs active={activeTab} onChange={(tab) => setActiveTab(tab)}>
          <Tabs.Tab value="notes">Notes</Tabs.Tab>
          <Tabs.Tab value="timeline">Timeline</Tabs.Tab>
        </Tabs>
        {activeTab === "notes" ? (
          <Box>
            <Notes
              entityId={match.params.customerId}
              notesAPI={usersNotesAPI}
            />
          </Box>
        ) : (
          <DonorTimeline donorId={match.params.customerId} />
        )}
      </Container>
    </UserProvider>
  );
};

export default withRouter(SingleCustomerComponent);
