// src/pages/regions/routes/route-single/google-map.js
import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker,
} from "react-google-maps";
import {
  REACT_APP_MP_GOOGLE_MAPS_API,
  REACT_APP_MP_PRIMARY_COLOR,
} from "config";
import useGoogleMapsScript from "hoc/with-maps";

const RouteMap = (props) => {
  const includeMap = useGoogleMapsScript(REACT_APP_MP_GOOGLE_MAPS_API);
  const [directionsService, setDirectionsService] = React.useState(null);
  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  React.useEffect(() => {
    if (includeMap) {
      setScriptLoaded(true);
    }
  }, [includeMap]);

  React.useEffect(() => {
    if (scriptLoaded) {
      setDirectionsService(new window.google.maps.DirectionsService());
    }
  }, [scriptLoaded]);

  React.useEffect(async () => {
    if (directionsService) {
      await calculateRoute();
    }
  }, [directionsService]);

  const [state, setState] = React.useState({
    directions: null,
    markers: [],
  });

  React.useEffect(async () => {
    if (props.locations.length > 0) {
      setMarkers();
    }
  }, [props.locations]);

  const createCustomMarkerIcon = (label, backgroundColor) => {
    return {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
          <circle cx="20" cy="20" r="19" fill="${backgroundColor}" stroke="#efefef" stroke-width="1" />
        </svg>
      `)}`,
      // scaledSize: new window.google.maps.Size(40, 40),
    };
  };

  const calculateRoute = async () => {
    const { locations } = props;
    if (locations.length === 0 || !window.google || !window.google.maps) return;

    while (locations.length > 25) {
      locations.pop(); // Remove one element from the end
      if (locations.length > 25) {
        locations.shift(); // Remove one element from the front
      }
    }

    const waypoints = locations.slice(1, -1).map((location) => ({
      location: location.address,
      stopover: true,
    }));

    const request = {
      origin: locations[0].address,
      destination: locations[locations.length - 1].address,
      waypoints: waypoints,
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    directionsService
      .route(request)
      .then((result) => {
        setState((prevState) =>
          prevState.directions !== result
            ? { ...prevState, directions: result }
            : prevState
        );
      })
      .catch((error) => {
        console.error("Error rendering directions:", error);
      });
  };

  const setMarkers = () => {
    const markers = props.locations.map((location, index) => ({
      position: { lat: location.lat, lng: location.lng },
      label: {
        text: `${location.label}`,
        color: `${location.addon ? "#333" : "#fff"}`,
        fontWeight: "bold",
      },
      icon: createCustomMarkerIcon(
        location.label,
        location.addon ? "#ffc107" : REACT_APP_MP_PRIMARY_COLOR
      ),
    }));

    setState((prevState) => ({ ...prevState, markers: markers }));
  };

  const { directions, markers } = state;

  const MapComponent = withGoogleMap(() => (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{
        lat: props.locations[2].lat,
        lng: props.locations[2].lng,
      }}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      }}
    >
      {markers.map((marker, index) => (
        <Marker
          key={`${index}`}
          position={marker.position}
          label={marker.label}
          icon={marker.icon}
        />
      ))}
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{ suppressMarkers: true }}
        />
      )}
    </GoogleMap>
  ));

  return (
    scriptLoaded && (
      <MapComponent
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{ height: "800px" }} />}
        mapElement={<div style={{ height: "100%" }} />}
      />
    )
  );
};

export default RouteMap;
