import { connect } from "react-redux";
import Reports from "./export-donors.component";

import withForm from "forms/form-class";
import { formValueSelector } from "redux-form";
import { downloadFile } from "utils/helpers";
import {
  formatDateHyphenatedYearFirstUtc,
  getDateWithoutTime,
  getDateWithoutTimeZone,
} from "utils/dates";

let Connected = withForm(Reports, "Reports", null, null, true, [], "Download");

function createQuery(data) {
  /* Report Types */
  let exportUrl = `/users/report/donor/${data.exportType}?`;

  if (data.startDate) {
    exportUrl += `&startDate=${getDateWithoutTimeZone(data.startDate)}`;
  }
  if (data.endDate) {
    exportUrl += `&endDate=${getDateWithoutTimeZone(data.endDate)}`;
  }

  if (data.registered === data.guest || data.registered) {
    exportUrl += `&filters[userTypeId]=4`;
  }
  if (data.registered === data.guest || data.guest) {
    exportUrl += `&filters[userTypeId]=5`;
  }

  if (data.allowMarketingCalls) {
    exportUrl += "&filters[allowMarketingCalls]=true";
  }

  // if (data.exportType === "inboundOutbound") {
  // 	exportUrl += `&inboundOutbound=true`;
  // }
  //
  // if (data.exportType === "uniqueGuestDonations") {
  // 	exportUrl += `&uniqueGuestDonations=true`;
  // }
  //
  // if (data.exportType === "uniqueNoPickups") {
  // 	exportUrl += `&uniqueNoPickups=true`;
  // }

  return exportUrl;
}

const mapStateToProps = (state) => {
  const selector = formValueSelector("Reports"); // <-- same as form name

  return {
    pickupStatuses: [...state.pickupStatuses.data],
    pickupSources: [...state.pickupSources.data],
    userFilterType: selector(state, "userFilterType"),
    onSubmit: (data) =>
      downloadFile(
        createQuery(data),
        `user-report-${formatDateHyphenatedYearFirstUtc(
          data.startDate
        )}-${formatDateHyphenatedYearFirstUtc(data.endDate)}.csv`
      ),
  };
};

export default connect(mapStateToProps)(Connected);
